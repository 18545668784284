import * as React from 'react';
export const routes = [
	{
		path: '',
		Component: React.lazy(() => import('../../Pages/Dashboard/Sales')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/sales',
		Component: React.lazy(() => import('../../Pages/Dashboard/Sales')),
		exact: true,
		isNavbar : true
	},	
	{
		path: '/dashboard/crm',
		Component: React.lazy(() => import('../../Pages/Dashboard/Crm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/marketing',
		Component: React.lazy(() => import('../../Pages/Dashboard/Marketing')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/procurment',
		Component: React.lazy(() => import('../../Pages/Dashboard/Procurment')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/civil',
		Component: React.lazy(() => import('../../Pages/Dashboard/Civil')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/profitable-report',
		Component: React.lazy(() => import('../../Pages/Reports')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/liaison',
		Component: React.lazy(() => import('../../Pages/Liaison')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/login',
		Component: React.lazy(() => import('../../Pages/Login')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/nextHearing/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/CourtCase')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/courtCase/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/CourtReminder')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/liaison-reports/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/Liaison')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/liaison-fallowups-reports/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/Task')),
		exact: true,
		isNavbar : false
	},

	
	{
		path: '/liaison_kns_report/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/LiaisonKnsReport')),
		exact: true,
		isNavbar : false
	},
	
	{
		path: '/liaison_shc_report/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/liaisonShcReport')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/liaison_all_report/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/LiaisonAllReport')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/liaison_own_report/:reportDate/:processOwnerID',
		Component: React.lazy(() => import('../../Pages/Reports/LiaisonOwnReport')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/profitable',
		Component: React.lazy(() => import('../../Pages/Reports/Profitable')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/task-wise-quantity/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/TaskWiseQuantity')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/progress',
		Component: React.lazy(() => import('../../Pages/Civil/ProjectProgress')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/budget',
		Component: React.lazy(() => import('../../Pages/Civil/ProjectBudget')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-nmr-planning',
		Component: React.lazy(() => import('../../Pages/Civil/ManPowerPlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-nmr-planning-details',
		Component: React.lazy(() => import('../../Pages/Civil/ManPowerDetailPlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-machine-planning',
		Component: React.lazy(() => import('../../Pages/Civil/MachinePlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-machine-planning-details',
		Component: React.lazy(() => import('../../Pages/Civil/MachinDetailPlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-type-planning',
		Component: React.lazy(() => import('../../Pages/Civil/MachinePlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-planning',
		Component: React.lazy(() => import('../../Pages/Civil/ResourcePlanning')),
		exact: true,
		isNavbar : true
	}

	
]
