import {GET_CIVIL_RESOURCE_PLANNING, GET_CIVIL_PROJECT_NAMES, GET_CIVIL_PROCESS_NAMES, 
    GET_CIVIL_TASK_NAMES, GET_CIVIL_RESOURCE_NAMES, GET_CIVIL_RESOURCE_TYPE_PLANNING, GET_CIVIL_BUDGET_NAMES} from './types';

const initialStateChart = {
    labels : [],
    datasets: [],
  };
const initialState = {
    civilResourcePlanning : initialStateChart,
    civilResourceTypePlanning : initialStateChart,
    civilBudgetPlanning : initialStateChart,
    getProjectNames : [],
    getProcessNames : [],
    getTaskNames : [],
    getResourceNames : []
    // courtCasePendingReminderCount : 0,
    // pendingLiaisonReports : [], 
    // pendingLiaisonReportsCount : 0,
}

const Report_reducer = (state = initialState, { type, payload }) => {
    switch(type)
    {
        case GET_CIVIL_RESOURCE_PLANNING:
            return Object.assign({}, state, { civilResourcePlanning: payload});                
        case GET_CIVIL_RESOURCE_TYPE_PLANNING:
            return Object.assign({}, state, { civilResourceTypePlanning: payload});      
        case GET_CIVIL_PROJECT_NAMES:
            return Object.assign({}, state, { getProjectNames: payload });
        case GET_CIVIL_PROCESS_NAMES:
            return Object.assign({}, state, { getProcessNames: payload });
        case GET_CIVIL_TASK_NAMES:
            return Object.assign({}, state, { getTaskNames: payload });        
        case GET_CIVIL_RESOURCE_NAMES:
            return Object.assign({}, state, { getResourceNames: payload });
        case GET_CIVIL_BUDGET_NAMES:
            return Object.assign({}, state, { civilBudgetPlanning: payload });
            
        default:  
            return state;
    }
}

export default Report_reducer;