import React, { useState } from 'react';
import ResponsiveAppBar from './AppBar'
import { styled, useTheme } from '@mui/material/styles';
import {Drawer as MuiDrawer, List, ListItem, ListItemText, Divider, ListItemIcon, Typography, ListItemButton, Collapse} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import './styles.scss'
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import MainRouter from './MainRouter'
import {Link, NavLink } from 'react-router-dom'
import {dateFormat} from '../../utils/date'

const _reportDate = dateFormat(new Date(), "yyyy-MM-DD")
const SidebarMenu = [
  {
    path : '/',
    label : 'Dashboard',
    newTab : false,
    children : [
      {
        path : `/dashboard/civil`,
        label : 'Civil',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/sales`,
        //path : `/`,
        label : 'Sales',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/crm`,
        label : 'CRM',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/marketing`,
        label : 'Marketing',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/procurment`,
        label : 'Procurment',
        newTab : true,
        children : []
      },
    ]
  },
  {
    path : '/civil',
    label : 'Civil',
    children : [
      {
        path : `/civil/resource-machine-planning`,
        label : 'Machine Planning',
        newTab : true,
        children : []
      },

      {
        path : `/civil/resource-nmr-planning`,
        label : 'Manpower Planning',
        newTab : true,
        children : []
      },

      {
        path : `/civil/resource-machine-planning-details`,
        label : 'Machine Planning Details',
        newTab : true,
        children : []
      },
  
      {
        path : `/civil/resource-nmr-planning-details`,
        label : 'Manpower Planning Details',
        newTab : true,
        children : []
      },

      {
        path : `/civil/resource-planning`,
        label : 'Resource Planning',
        newTab : true,
        children : []
      },

      {
        path : `/civil/budget`,
        label : 'Project Budget',
        newTab : true,
        children : []
      },
      {
        path : `/civil/progress`,
        label : 'Project Progress',
        newTab : true,
        children : []
      },
    ]
  },
  {
    path : '/Reports',
    label : 'Liaison',
    children : [

      {
        path :  `/liaison-reports/reportDate=${_reportDate}`,
        label : 'Liaison Meeting',
        newTab : true,
        children : []
      },

      {
        path : `/nextHearing/reportDate=${_reportDate}`,
        label : 'Next Hearing',
        newTab : true,
        children : []
      },

      {
        path :  `/courtCase/reportDate=${_reportDate}`,
        label : 'Not updated Court Case',
        newTab : true,
        children : []
      },

      {
        path :  `/liaison_kns_report/reportDate=${_reportDate}`,
        label : 'Liaison Kns Report',
        newTab : true,
        children : []
      },
      {
        path :  `/liaison_shc_report/reportDate=${_reportDate}`,
        label : 'Liaison SHC Report',
        newTab : true,
        children : []
      },
      
      // {
      //   path :  `/liaison-fallowups-reports/reportDate=${_reportDate}`,
      //   label : 'Pending Liaison',
      //   newTab : true,
      //   children : []
      // },
      
      {
        path :  `/liaison_all_report/reportDate=${_reportDate}`,
        label : 'Liaison All Report',
        newTab : true,
        children : []
      },
      // {
      //   path :  `/liaison_own_report/reportDate=${_reportDate}`,
      //   label : 'Liaison Own Report',
      //   newTab : true,
      //   children : []
      // },
    ]
  }
]



const CollpseComponent = ({ item }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open);
  };
  return <>
    <ListItemButton onClick={handleClick}>
      <ListItemIcon>
        <InboxIcon />
      </ListItemIcon>
      <ListItemText primary={item.label} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {item.children.map(sub => {
          return <NavLink key={sub.label} to={sub.path} target={sub.newTab ? "_blank" : ""}  style={({ isActive }) => ({
            color: isActive ? '#fff' : '#545e6f',
            background: isActive ? '#7600dc' : '#f0f0f0',
          })}>
            <ListItemButton sx={{ pl: 1 }}>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={sub.label} className="childrenlabel"/>
            </ListItemButton>
          </NavLink>
        })}
      </List>
    </Collapse>
  </>
}

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    background : "#F03031 !important",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    background : "#F03031 !important",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(7)} + 1px)`,
    },
  });
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

function Navbar(props) {
    const [isSidebar, setSidebar] = useState(false);
    const theme = useTheme();

    const ontoggleClick = () =>{
        setSidebar(!isSidebar)
    }

    const handleDrawerClose = () => {
        setSidebar(!isSidebar)
      };
    return (
        <div className='navbar'>
            <div className='header'><ResponsiveAppBar ontoggleClick={ontoggleClick} isSidebar={isSidebar}/></div>
            <div className='main-body'>
                <div className={"sidebar"}> 
                    <Drawer variant="permanent" open={isSidebar}>
                        <DrawerHeader>
                            {isSidebar &&
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ ml: 0, display: { xs: 'flex', md: 'flex' }, color : "#fff" }}
                                >
                                    KNS 
                                </Typography>
                            }
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <List>
                        {SidebarMenu.map((_menu, index) => (
                          <>
                          {
                            _menu.children.length > 0 ?
                            <CollpseComponent item={_menu} />
                            :
                            <Link key={_menu.label} to={_menu.path}>
                              <ListItem button >
                                <ListItemIcon>
                                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                  </ListItemIcon>
                                <ListItemText primary={_menu.label} />
                              </ListItem>
                            </Link>
                          }
                          </>
                           
                        ))}
                        </List>
                        <Divider />
                        {/* <List>
                        {['All mail', 'Trash', 'Spam'].map((text, index) => (
                            <ListItem button key={text}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                            </ListItem>
                        ))}
                        </List> */}
                    </Drawer>
                </div>
                <div className='content'>
                    <div>
                    {/* <Employee  type="list" /> */}
                    {/* <MainRouter /> */}
                    {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;