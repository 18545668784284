import { MAIN_LOAD_FALSE,MAIN_LOAD_TRUE, DISPLAY_MESSAGE,DISPLAY_ERROR, 
    SUCCESS_STATUS,OTP_VALID_FAILS, OTP_SENT_SUCCESS, OTP_VALID_SUCCESS
} from './types';

const initialState = {
    otp_sent: false,
    is_login: false,
    auth_user: {},
    img_url: 'https://www.goocampus.in/goocampus_new/',
    main_loader : false,
    messageInfo : {text : null, type : "info"},
    error : "",
    status : ""
}

const User_reducer = (state = initialState, { type, payload }) => {
    switch(type)
    {
        case MAIN_LOAD_TRUE:
            return Object.assign({}, state, { main_loader: true });
        
        case MAIN_LOAD_FALSE:
            return Object.assign({}, state, { main_loader: false });

        case DISPLAY_MESSAGE:
            return Object.assign({}, state, { messageInfo: payload });
            
        case DISPLAY_ERROR:
            return Object.assign({}, state, { error: payload.error });

        case SUCCESS_STATUS:
            return Object.assign({}, state, { status: payload.status });
            
        case OTP_SENT_SUCCESS:
            return Object.assign({}, state, { otp_sent: true });
        
        case OTP_VALID_FAILS:
            return Object.assign({}, state, { valid_otp: false });

        case OTP_VALID_SUCCESS:
                return Object.assign({}, state, { valid_otp: true });
            
                
        default:  
            return state;
    }
}

export default User_reducer;