export const GET_PROFITABLE_REPORTS = 'GET_PROFITABLE_REPORTS';
export const GET_LIASONPROCESSOWNER = 'GET_LIASONPROCESSOWNER';
export const GET_PROJECT_NAMES = 'GET_PROJECT_NAMES';
export const GET_TASK_DELAYS = 'GET_TASK_DELAYS';
export const GET_PROFITABLE_REPORT_COUNT = 'GET_PROFITABLE_REPORT_COUNT';
export const GET_PENDING_TASK = 'GET_PENDING_TASK';
export const GET_LIAISON_PROJECT_NAMES = 'GET_LIAISON_PROJECT_NAMES';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
// export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
// export const OTP_VALID_SUCCESS = 'OTP_VALID_SUCCESS';
// export const OTP_VALID_FAILS = 'OTP_VALID_FAILS';



