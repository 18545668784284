import axios from 'axios';
import { getCivilResourcePlanningSuccess } from '../Redux/Civil/action';

export const BaseUrl = 'https://knsonline.in/RestAPI/';
export const DocumentUrl = 'https://knsonline.in/';

export const ApiPaths = {
    LOGIN: 'userLogin',
    GETDASHBOARDDATA: 'getDashboardData',

    PROFITABLE_REPORTS : 'getProcessPlan',
    LIASONPROCESSOWNER : 'getLiasonProcessOwner1',
    GETPROJECTS : 'getProjects',
    GETLIAISONPROJECTS : 'getLiaisonProjects',
    GETTASKDELAY : 'getTaskDelayReason',
    GETPENDINGTASK : 'getPendingTasks',
    GETCOURTCASEPENDINGREM : 'getCourtCasePendingReminder',
    GETCOURTCASEREM : 'getCourtCaseReminder',
    PENDINGLIAISONREPORT : 'getLiaisonReport',
    PENDINGLIAISONREPORT_1 : 'getLiaisonReport1',
    CIVILRESOURCEPLANNING : 'getCivilResourcePlanning',
    CIVILRESOURCETYPEPLANNING: 'getCivilTaskOveralResourcePlanning',
    GETCIVILPROJECTS: "getCivilProjectPlan",
    GETCIVILPROCESS: "getCivilProcessPlan",
    GETCIVILTASKS: "getCivilTaskPlan",
    GETCIVILRESOURCE: "getCivilProjectResource",
    GETCIVILBUDGET: "getCivilTaskBudget",
    GETCIVILPROGRESS: "getCivilProjectProgress"
};

export const ApiService = axios.create({
    baseURL : BaseUrl + 'v2/',
    headers : {
        'Accept' : 'application/json',
        'Content-Type' : 'application/x-www-form-urlencoded',
    }
});

export const SetAuthToken = (token) => {
    if(token)
    {
        ApiService.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    else
    {
        delete ApiService.defaults.headers.common['Authorization'];
    }
}

export const ApiStatus = {
    OTP_SENT: 'OTP sent',
}