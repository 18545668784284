import { GET_COURT_CASE_REMINDER, GET_COURT_CASE_PENDING_REMINDER, GET_PENDING_LIAISON_REPORTS} from './types';

const initialState = {
    courtCaseReminder : [],
    courtCaseReminderCount : 0,
    courtCasePendingReminder : [],
    courtCasePendingReminderCount : 0,
    pendingLiaisonReports : [], 
    pendingLiaisonReportsCount : 0,
}

const Report_reducer = (state = initialState, { type, payload }) => {
    switch(type)
    {
        case GET_COURT_CASE_REMINDER:
            return Object.assign({}, state, { courtCaseReminder: payload.case, courtCaseReminderCount :  payload.totalCount});        
        case GET_COURT_CASE_PENDING_REMINDER:
            return Object.assign({}, state, { courtCasePendingReminder: payload.case,  courtCasePendingReminderCount :  payload.totalCount});
        case GET_PENDING_LIAISON_REPORTS:
            return Object.assign({}, state, { pendingLiaisonReports: payload.process,  pendingLiaisonReportsCount : payload.totalCount});
        default:  
            return state;
    }
}

export default Report_reducer;