export const GET_CIVIL_RESOURCE_PLANNING = 'GET_CIVIL_RESOURCE_PLANNING';
export const GET_CIVIL_PROJECT_NAMES = 'GET_CIVIL_PROJECT_NAMES';
export const GET_CIVIL_PROCESS_NAMES = 'GET_CIVIL_PROCESS_NAMES';


export const GET_CIVIL_TASK_NAMES = 'GET_CIVIL_TASK_NAMES';
export const GET_CIVIL_RESOURCE_NAMES = 'GET_CIVIL_RESOURCE_NAMES';
export const GET_CIVIL_RESOURCE_TYPE_PLANNING = 'GET_CIVIL_RESOURCE_TYPE_PLANNING';
export const GET_CIVIL_BUDGET_NAMES = 'GET_CIVIL_BUDGET_NAMES';
// export const SUCCESS_STATUS = 'SUCCESS_STATUS';
// export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
// export const OTP_VALID_SUCCESS = 'OTP_VALID_SUCCESS';
// export const OTP_VALID_FAILS = 'OTP_VALID_FAILS';



