import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../../utils/ApiService';
import {GET_CIVIL_RESOURCE_PLANNING, GET_CIVIL_PROJECT_NAMES, GET_CIVIL_PROCESS_NAMES, 
    GET_CIVIL_TASK_NAMES, GET_CIVIL_RESOURCE_NAMES, GET_CIVIL_RESOURCE_TYPE_PLANNING, GET_CIVIL_BUDGET_NAMES} from './types';
import {mainLoadingTrue, mainLoadingFalse} from '../User/action'
import { toast } from 'react-toastify';

export const getResourcePlanning_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.CIVILRESOURCEPLANNING, data).then(response => {
            const {data}  = response;
            console.log("scheduleData", data.scheduleData)
            dispatch(getCivilResourcePlanningSuccess(data.scheduleData))
            dispatch(mainLoadingFalse());
            // if(!data.error){
            //     dispatch(getCivilResourcePlanningSuccess(data.scheduleData))
            // }
            // else{
            //     dispatch(getCivilResourcePlanningSuccess({}))
            // }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilResourcePlanningSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilResourcePlanningSuccess = (data) => {
    return {
        type : GET_CIVIL_RESOURCE_PLANNING,
        payload : data
    }
}

export const getResourceTypePlanning_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.CIVILRESOURCETYPEPLANNING, data).then(response => {
            const {data}  = response;
            console.log("scheduleData", data.scheduleData)
            dispatch(getCivilResourceTypePlanningSuccess(data.scheduleData))
            dispatch(mainLoadingFalse());
            // if(!data.error){
            //     dispatch(getCivilResourcePlanningSuccess(data.scheduleData))
            // }
            // else{
            //     dispatch(getCivilResourcePlanningSuccess({}))
            // }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilResourceTypePlanningSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilResourceTypePlanningSuccess = (data) => {
    return {
        type : GET_CIVIL_RESOURCE_TYPE_PLANNING,
        payload : data
    }
}

export const getProjectsNames_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILPROJECTS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            console.log("data", data)
            if(!data.error){
                dispatch(getProjectsNamesSuccess(data.projectData))
            }
            else{
                dispatch(getProjectsNamesSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectsNamesSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectsNamesSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_NAMES,
        payload : data
    }
}

export const getResourceNames_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILRESOURCE, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            console.log("data", data)
            if(!data.error){
                dispatch(getResourceNamesSuccess(data.resourceData))
            }
            else{
                dispatch(getResourceNamesSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getResourceNamesSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getResourceNamesSuccess = (data) => {
    return {
        type : GET_CIVIL_RESOURCE_NAMES,
        payload : data
    }
}

export const getCivilProcess_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILPROCESS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilProcessSuccess(data.processData))
            }
            else{
                dispatch(getCivilProcessSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProcessSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProcessSuccess = (data) => {
    return {
        type : GET_CIVIL_PROCESS_NAMES,
        payload : data
    }
}

export const getCivilTasks_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILTASKS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilTasksSuccess(data.taskData))
            }
            else{
                dispatch(getCivilTasksSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilTasksSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilTasksSuccess = (data) => {
    return {
        type : GET_CIVIL_TASK_NAMES,
        payload : data
    }
}

export const getCivilBudget_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILBUDGET, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilBudgetSuccess(data.scheduleData))
            }
            else{
                dispatch(getCivilBudgetSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilBudgetSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilBudgetSuccess = (data) => {
    return {
        type : GET_CIVIL_BUDGET_NAMES,
        payload : data
    }
}



export const getCivilProgress_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILPROGRESS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilProgressSuccess(data.scheduleData))
            }
            else{
                dispatch(getCivilProgressSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProgressSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProgressSuccess = (data) => {
    return {
        type : GET_CIVIL_BUDGET_NAMES,
        payload : data
    }
}

