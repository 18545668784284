import React, {useState, useEffect} from 'react'
import logo from './logo.svg';
import './App.scss';
import MainRouter from './Components/Routing/MainRouter'
import {useSelector, useDispatch} from 'react-redux'
import {displayMessage} from './Redux/User'
import Snackbar from './Components/Alert'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function App() {
  const [alertText, setAlertText]= useState({text : null, type : "info"});
  const [loading, setLoading]= useState(true);
  const messageInfo = useSelector(state => state.user.messageInfo)
  const main_loader = useSelector(state => state.user.main_loader)
  const dispatch = useDispatch();
  console.log("loading", loading)
  useEffect(()=>{
    if(messageInfo['text']){
        setAlertText(messageInfo)
      setTimeout(()=>{
        dispatch(displayMessage({text : null, type : "info"}))
        setAlertText({text : null, type : "info"})
      }, 2000)
    }else{
      setAlertText({text : null, type : "info"})
    }
  },[messageInfo])

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(main_loader)
    }, 1000)
  },[main_loader])

  return (
    <div className="App">
      {
        !!alertText.text && <Snackbar open={!!alertText.text} alertText={alertText}/>
      }

      {loading &&  <div className={"loader"}>
            <CircularProgress  color="secondary" /> 
          </div>
      }
      
      <section>
        <MainRouter />
      </section>
    </div>
  );
}

export default App;
